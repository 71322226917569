import React from 'react'
import PropTypes from 'prop-types'
import PreviewCompatibleImage from '../components/PreviewCompatibleImage'

const FeatureGrid = ({ gridItems }) => (
  <div className="grid-wrapper">
    <div className="col-12">
      <header className="major">
        <h2>Adipiscing amet consequat</h2>
      </header>
      <p>Ante nunc accumsan et aclacus nascetur ac ante amet sapien sed.</p>
    </div>
    {gridItems.map(item => (
      <div className="col-3">
        <span className="image fit"><PreviewCompatibleImage imageInfo={item} /></span>
        <h3>Magna feugiat lorem</h3>
        <p>{item.text}</p>
        <ul className="actions">
          <li><a href="#" className="button">More</a></li>
        </ul>
      </div>
    ))}
  </div>
)

FeatureGrid.propTypes = {
  gridItems: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
      text: PropTypes.string,
    })
  ),
}

export default FeatureGrid
